const FUTURE_ROUTE_GROUP_ITEMS_KEY = 'future_route_group_items';

interface RouteGroupItemDateMap {
  [id: number]: {
    dates: string[];
  };
}

export const getStoredRouteGroupItems = (): RouteGroupItemDateMap => {
  const stored = localStorage.getItem(FUTURE_ROUTE_GROUP_ITEMS_KEY);
  return stored ? JSON.parse(stored) : {};
};

export const setStoredRouteGroupItems = (items: RouteGroupItemDateMap) => {
  localStorage.setItem(FUTURE_ROUTE_GROUP_ITEMS_KEY, JSON.stringify(items));
};

export const updateStoredRouteGroupItems = (newItems: { [id: number]: string }) => {
  const existingItems = getStoredRouteGroupItems();
  const updatedItems = { ...existingItems };

  for (const [id, newDate] of Object.entries(newItems)) {
    const numericId = Number(id);
    const existing = existingItems[numericId];

    if (!existing || !existing.dates) {
      updatedItems[numericId] = { dates: [newDate] };
    } else if (!existing.dates.includes(newDate)) {
      updatedItems[numericId] = {
        dates: [newDate, ...existing.dates],
      };
    }
  }

  setStoredRouteGroupItems(updatedItems);
};

export const hasItemsForToday = (requestedIds: number[]): boolean => {
  const itemsWithDates = getStoredRouteGroupItems();
  const today = new Date().toISOString().split('T')[0];
  return requestedIds.some((id) => itemsWithDates[id]?.dates[0] === today);
};
