import React from 'react';
import { cn } from '@/utils/cn';

interface PaginationControlsProps {
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
  pageSize: number;
  onPageSizeChange: (size: number) => void;
  showPageSize?: boolean;
}

export const PaginationControls = ({
  currentPage,
  totalPages,
  setCurrentPage,
  pageSize,
  onPageSizeChange,
  showPageSize = false,
}: PaginationControlsProps) => {
  return (
    <div className="flex items-center justify-between gap-2 p-4">
      <div className="flex items-center gap-4">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 0}
          className={cn('px-4 py-2 text-lg font-semibold border rounded-lg bg-gray-300 dark:bg-gray-800 dark:text-white', {
            'text-gray-400 cursor-not-allowed': currentPage === 0,
            'hover:bg-gray-400 dark:hover:bg-gray-400': currentPage !== 0,
          })}
        >
          Previous
        </button>
        <span className="text-lg">
          Page {currentPage + 1} of {totalPages}
        </span>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage >= totalPages - 1}
          className={cn('px-4 py-2 text-lg font-semibold border rounded-lg bg-gray-300 dark:bg-gray-800 dark:text-white', {
            'text-gray-400 cursor-not-allowed': currentPage >= totalPages - 1,
            'hover:bg-gray-400 dark:hover:bg-gray-400': currentPage < totalPages - 1,
          })}
        >
          Next
        </button>
      </div>
      {showPageSize && (
        <select
          value={pageSize}
          onChange={(e) => onPageSizeChange(Number(e.target.value))}
          className="px-4 py-2 text-lg bg-gray-300 border rounded-lg hover:bg-gray-400 dark:hover:bg-gray-400 dark:bg-gray-800 dark:text-white"
        >
          {[10, 20, 30, 40, 50].map((size) => (
            <option key={size} value={size}>
              {size} per page
            </option>
          ))}
        </select>
      )}
    </div>
  );
};
