import httpService from '../lib/http.service';
import {
  GetRouteGroupItemsQueryDto,
  GetRouteGroupItemWaypointsQueryDto,
  ObjectService,
  RouteGroupItem,
  RouteGroupItemWaypoint,
} from '@ekt-group/general-purpose-api-interfaces';
import { RouteGroupItemWaypointsSideloading } from '@ekt-group/general-purpose-api-interfaces/src/types/route-group-item-waypoints.enum';
import { RouteGroupItemSideloading } from '@ekt-group/general-purpose-api-interfaces/src/types/route-group-items.enum';
import { getFutureDaysDate, getTodayDate } from '@/helpers/dateHelper';
import { UserProfession } from '@/features/auth';
import { updateStoredRouteGroupItems } from '@/helpers/localStorage/routeGroupItemsHelper';

export interface GetWaypointsDto {
  routeGroupItemIds?: number[];
  waypointIds?: number[];
}

export interface GetRouteGroupItemsListDto {
  userId: number;
  role: UserProfession;
  onBackgroundDataLoaded?: (data: RouteGroupItem[]) => void;
}

const REQUEST_DELAY_MS = 1000;
const INITIAL_DAYS_TO_FETCH = 2;
const TOTAL_DAYS_TO_FETCH = 7;

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const getParamsForDay = (baseParams: GetRouteGroupItemsQueryDto, date: string, userId: number, role?: UserProfession) => {
  const params = [];
  const dayParams = { ...baseParams, startDate: date, endDate: date };

  if (!role) {
    params.push({ ...dayParams, driverIds: userId });
  }
  if ([UserProfession.Driver, UserProfession.DriverLoader].includes(role)) {
    params.push({ ...dayParams, driverIds: userId });
    params.push({ ...dayParams, loaderIds: userId });
  }
  if (role === UserProfession.Loader) {
    params.push({ ...dayParams, loaderIds: userId });
  }

  return params;
};

export default {
  async getRouteGroupItemsList({ userId, role, onBackgroundDataLoaded }: GetRouteGroupItemsListDto) {
    const baseParams: GetRouteGroupItemsQueryDto = {
      sideloading: [
        RouteGroupItemSideloading.RouteGroup,
        RouteGroupItemSideloading.Car,
        RouteGroupItemSideloading.Driver,
        RouteGroupItemSideloading.Loader,
        RouteGroupItemSideloading.Base,
      ],
      disablePagination: true,
    };

    // Initial load for today and tomorrow
    const initialRequestParams = [];
    for (let day = 0; day < INITIAL_DAYS_TO_FETCH; day++) {
      const date = getFutureDaysDate(day);
      initialRequestParams.push(...getParamsForDay(baseParams, date, userId, role));
    }

    const initialResponses = [];
    for (const params of initialRequestParams) {
      const response = await httpService.get<RouteGroupItem[]>('/route-group-items', { params });
      initialResponses.push(response);
      if (initialRequestParams.indexOf(params) < initialRequestParams.length - 1) {
        await delay(REQUEST_DELAY_MS);
      }
    }

    const initialData = initialResponses.flat().reduce((routeGroupItems, { data }) => {
      return [...routeGroupItems, ...data];
    }, []);

    // Store route group items with their dates
    const routeItemsWithDates = initialData.reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: item.date.split('T')[0],
      }),
      {},
    );

    updateStoredRouteGroupItems(routeItemsWithDates);

    // Background load for remaining days
    (async () => {
      const backgroundResponses = [];
      for (let day = INITIAL_DAYS_TO_FETCH; day < TOTAL_DAYS_TO_FETCH; day++) {
        const date = getFutureDaysDate(day);
        const dayParams = getParamsForDay(baseParams, date, userId, role);

        for (const params of dayParams) {
          const response = await httpService.get<RouteGroupItem[]>('/route-group-items', { params });
          backgroundResponses.push(response);
          await delay(REQUEST_DELAY_MS);
        }
      }

      const backgroundData = backgroundResponses.flat().reduce((routeGroupItems, { data }) => {
        return [...routeGroupItems, ...data];
      }, []);

      const newItems = backgroundData.reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: item.date.split('T')[0],
        }),
        {},
      );

      updateStoredRouteGroupItems(newItems);
      onBackgroundDataLoaded?.(backgroundData);
    })();

    return initialData;
  },
  async getWaypoints({ routeGroupItemIds, waypointIds }: GetWaypointsDto) {
    const params: GetRouteGroupItemWaypointsQueryDto = {
      sideloading: [
        RouteGroupItemWaypointsSideloading.Owner,
        RouteGroupItemWaypointsSideloading.ObjectItem,
        RouteGroupItemWaypointsSideloading.Object,
        RouteGroupItemWaypointsSideloading.ObjectItemDischarge,
        RouteGroupItemWaypointsSideloading.Contract,
        RouteGroupItemWaypointsSideloading.Kojv,
        RouteGroupItemWaypointsSideloading.KojvService,
        RouteGroupItemWaypointsSideloading.WaypointLogDetails,
      ],
      disablePagination: true,
    };

    if (routeGroupItemIds && routeGroupItemIds.length > 0) {
      params.routeGroupItemIds = routeGroupItemIds;
    }
    if (waypointIds && waypointIds.length > 0) {
      params.ids = waypointIds;
    }

    return httpService.get<RouteGroupItemWaypoint[]>('/route-group-item-waypoints', {
      params,
    });
  },
  async getServiceWaypoints({ routeGroupItemIds, waypointIds }: GetWaypointsDto) {
    const params: any = {
      sideloading: 'object,objectItem,contract,owner,relatedServices',
      isServiceDisabled: false,
      disablePagination: true,
    };

    if (routeGroupItemIds) {
      params['routeGroupItemIds'] = routeGroupItemIds.join();
    }
    if (waypointIds && waypointIds.length > 0) {
      params['ids'] = waypointIds.join();
    }

    return httpService.get<ObjectService[]>('/objects/services', {
      params,
    });
  },
};
