import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { useTranslation } from 'react-i18next';
import generalService from '../services/general.service';
import gatesService from '../features/route/api/gates.service';
import keysAndRemotesService from '../features/route/api/keys-and-remotes.service';
import gasCardsService from '../features/fueling/api/gas-cards.service';
import waypointsService from '../services/discharge-waypoints.service';
import kojvService from '../features/route/api/kojv.service';
import { isRouteTypeWithWaypoints } from '@/features/home';
import { getUnloadPoints } from '@/features/unloading';
import { ObjectService, RouteGroupItem } from '@ekt-group/general-purpose-api-interfaces';

const REQUEST_DELAY_MS = 1000;

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

interface WaypointsRelatedIds {
  garbageIds: number[];
  kojvIds: number[];
}

export function usePreCache({ routeGroupItems }: { routeGroupItems: RouteGroupItem[] }) {
  const [isPreCacheResolved, setIsPreCacheResolved] = useState(false);
  const isOffline = useOfflineStatus();
  const { t } = useTranslation('offline');

  const getWaypointsByRouteGroupItemIds = useCallback(async () => {
    if (routeGroupItems) {
      const allWaypointsResponses = [];
      const filteredRoutes = routeGroupItems.filter(({ sheetType }) => isRouteTypeWithWaypoints(sheetType));

      for (const route of filteredRoutes) {
        const response = await (route.isDischargeSheet
          ? generalService.getWaypoints({ routeGroupItemIds: [route.id] })
          : generalService.getServiceWaypoints({ routeGroupItemIds: [route.id] }));
        allWaypointsResponses.push(response);

        if (filteredRoutes.indexOf(route) < filteredRoutes.length - 1) {
          await delay(REQUEST_DELAY_MS);
        }
      }

      const garbageIds: number[] = [];
      const kojvIds: number[] = [];

      for (const response of allWaypointsResponses) {
        if (response.data) {
          for (const waypoint of response.data) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { kojv, objectItem } = waypoint;
            const { garbageId } = objectItem;

            if (!garbageIds.includes(garbageId)) {
              garbageIds.push(garbageId);
            }
            if (!kojvIds.includes(kojv?.id)) {
              kojvIds.push(kojv?.id);
            }
          }
        }
      }

      return { garbageIds, kojvIds };
    }
  }, [routeGroupItems]);

  const getGarbageIcons = useCallback(async (garbageIds: number[]) => {
    for (const id of garbageIds) {
      const image = new Image();
      image.src = `https://toits.ekkt.ee/upload/icons/garbage_id_${id}.jpg`;
      if (garbageIds.indexOf(id) < garbageIds.length - 1) {
        await delay(REQUEST_DELAY_MS);
      }
    }
  }, []);

  const getKojvConditions = useCallback(async (kojvIds: number[]) => {
    for (const id of kojvIds) {
      await kojvService.getKojvConditions(id);
      if (kojvIds.indexOf(id) < kojvIds.length - 1) {
        await delay(REQUEST_DELAY_MS);
      }
    }
  }, []);

  const getKeysAndRemotesByRouteGroupItemIds = useCallback(async () => {
    if (routeGroupItems) {
      await gatesService.getGates();
      await delay(REQUEST_DELAY_MS);

      for (const route of routeGroupItems) {
        await keysAndRemotesService.getKeysAndRemotes(route.id);
        if (routeGroupItems.indexOf(route) < routeGroupItems.length - 1) {
          await delay(REQUEST_DELAY_MS);
        }
      }
    }
  }, [routeGroupItems]);

  const getFuelCardsByRouteGroupItemIds = useCallback(async () => {
    if (routeGroupItems) {
      for (const route of routeGroupItems) {
        await gasCardsService.getGasCards(route.id);
        if (routeGroupItems.indexOf(route) < routeGroupItems.length - 1) {
          await delay(REQUEST_DELAY_MS);
        }
      }
    }
  }, [routeGroupItems]);

  const getUnloadPointsByRouteGroupItemIds = useCallback(async () => {
    if (routeGroupItems) {
      for (const route of routeGroupItems) {
        await getUnloadPoints(route.id);
        if (routeGroupItems.indexOf(route) < routeGroupItems.length - 1) {
          await delay(REQUEST_DELAY_MS);
        }
      }
    }
  }, [routeGroupItems]);

  const getDischargeAndFailureReasons = useCallback(async () => {
    await waypointsService.getDischargeFailureReasons();
  }, []);

  const runPreCache = useCallback(async () => {
    const { garbageIds, kojvIds } = await getWaypointsByRouteGroupItemIds();

    // Run each group of requests sequentially with delays between groups
    await getGarbageIcons(garbageIds.filter(Boolean));
    await delay(REQUEST_DELAY_MS);

    await getKojvConditions(kojvIds.filter(Boolean));
    await delay(REQUEST_DELAY_MS);

    await getKeysAndRemotesByRouteGroupItemIds();
    await delay(REQUEST_DELAY_MS);

    await getFuelCardsByRouteGroupItemIds();
    await delay(REQUEST_DELAY_MS);

    await getUnloadPointsByRouteGroupItemIds();
    await delay(REQUEST_DELAY_MS);

    await getDischargeAndFailureReasons();

    setIsPreCacheResolved(true);
  }, [
    getWaypointsByRouteGroupItemIds,
    getGarbageIcons,
    getKojvConditions,
    getKeysAndRemotesByRouteGroupItemIds,
    getFuelCardsByRouteGroupItemIds,
    getUnloadPointsByRouteGroupItemIds,
    getDischargeAndFailureReasons,
  ]);

  useEffect(() => {
    if (!routeGroupItems?.length || isPreCacheResolved || isOffline) {
      return;
    }

    runPreCache();
  }, [runPreCache, routeGroupItems, isOffline, isPreCacheResolved]);
}
