import React, { forwardRef, useState, useEffect } from 'react';
import ModalWrapper from './ModalWrapper';
import ToggleButton from '../elements/ToggleButton';
import { useTheme } from '@/contexts/themeContext';
import Button from '../elements/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppMode } from '@/types/common';
import { useUserProfession } from '@/features/home';
import { useAppMode } from '@/hooks/useAppMode';
import { useAppDispatch, useAppSelector } from '@/store/types';
import { selectIsSyncingEnabled, setSyncingEnabled } from '@/store/settings/slice';
import { useSocketStatus } from '@/contexts/socketContext';
import { cn } from '@/utils/cn';
import CacheStorageModal from './CacheStorageModal';
import CheckMark from '@/components/icons/CheckMark';
import CrossMark from '@/components/icons/CrossMark';

interface SettingsModalProps {
  isVisible: boolean;
  onClose: () => void;
}

interface SettingToggleOption {
  label: string;
  value: boolean;
  onChange: (checked: boolean) => void;
  id: string;
}

const SettingsModal = forwardRef<HTMLDivElement, SettingsModalProps>(({ isVisible, onClose }, ref) => {
  const { theme, setTheme } = useTheme();
  const { t } = useTranslation();
  const { isUserLoader } = useUserProfession();
  const [isCacheModalVisible, setIsCacheModalVisible] = useState(false);
  const [isServiceWorkerActive, setIsServiceWorkerActive] = useState(false);

  const { appMode, setAppMode } = useAppMode();
  const navigate = useNavigate();
  const isSocketConnected = useSocketStatus();

  const dispatch = useAppDispatch();
  const isSyncingEnabled = useAppSelector(selectIsSyncingEnabled);

  useEffect(() => {
    const checkServiceWorker = async () => {
      try {
        const registration = await navigator.serviceWorker?.getRegistration();
        if (!registration?.active || !window?.caches) {
          setIsServiceWorkerActive(false);
          return;
        }

        const cacheKeys = await window.caches.keys();
        const hasRequiredCaches =
          cacheKeys.some((key) => key.includes('route-group-items')) &&
          cacheKeys.some((key) => key.includes('waypoints')) &&
          cacheKeys.some((key) => key === 'user');

        setIsServiceWorkerActive(hasRequiredCaches);
      } catch (error) {
        console.error('Error checking service worker status:', error);
        setIsServiceWorkerActive(false);
      }
    };

    checkServiceWorker();
  }, [isVisible]);

  const handleModeChange = (checked: boolean) => {
    const mode = checked ? AppMode.Advanced : AppMode.Basic;
    setAppMode(mode);
    localStorage.setItem('appMode', mode);
    navigate('/');
    onClose();
  };

  const handleDarkModeChange = (checked: boolean) => {
    setTheme(checked ? 'dark' : 'light');
  };

  const handleSyncingEnabledChange = (checked: boolean) => {
    dispatch(setSyncingEnabled(checked));
  };

  const options: SettingToggleOption[] = [
    ...(!isUserLoader()
      ? [
          {
            id: 'advancedMode',
            label: t('advancedMode'),
            value: appMode === AppMode.Advanced,
            onChange: handleModeChange,
          },
        ]
      : []),
    {
      id: 'darkMode',
      label: t('darkMode'),
      value: theme === 'dark',
      onChange: handleDarkModeChange,
    },
    {
      id: 'syncingEnabled',
      label: t('syncingEnabled'),
      value: isSyncingEnabled,
      onChange: handleSyncingEnabledChange,
    },
  ];

  const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClose();
    event.stopPropagation();
  };

  return (
    <>
      <ModalWrapper
        ref={ref}
        isVisible={isVisible}
        title={t('settings', { ns: 'common' })}
        titleAdditionalElement={<small className={'text-gray-600 ml-auto'}>{__APP_VERSION__}</small>}
        className="self-start mt-10 h-fit"
      >
        <div className="settings-modal">
          <div className="settings-modal__list">
            {options.map((option) => (
              <div className="settings-modal__list-item" key={option.id}>
                <label className="settings-modal__label" htmlFor={option.id}>
                  {option.label}
                </label>
                <ToggleButton checked={option.value} inputId={option.id} onChange={option.onChange} />
              </div>
            ))}
            <div className="settings-modal__list-item" key="socket">
              <label className="settings-modal__label">
                {t('socketConnection', {
                  ns: 'common',
                })}
              </label>
              <span className={cn('font-bold', { 'text-green-500': isSocketConnected, 'text-red-500': !isSocketConnected })}>
                {isSocketConnected
                  ? t('connected', {
                      ns: 'common',
                    })
                  : t('disconnected', {
                      ns: 'common',
                    })}
              </span>
            </div>
            <div className="settings-modal__list-item">
              <label className="settings-modal__label">{t('offlineReady', { ns: 'common' })}</label>
              <span className={cn('font-bold')}>
                {isServiceWorkerActive ? (
                  <CheckMark size="sm" className="text-green-500" />
                ) : (
                  <CrossMark size="sm" className="text-red-500" />
                )}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-2 settings-modal__footer">
            <Button text={t('viewCacheStorage', { ns: 'cacheModal' })} size="md" onClick={() => setIsCacheModalVisible(true)} wide />
            <Button text={t('close')} color={'disabled'} size={'md'} wide onClick={handleClose} />
          </div>
        </div>
      </ModalWrapper>
      <CacheStorageModal isVisible={isCacheModalVisible} onClose={() => setIsCacheModalVisible(false)} />
    </>
  );
});

SettingsModal.displayName = 'SettingsModal';

export default SettingsModal;
