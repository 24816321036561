import { AllDeviceTask } from '@ekt-group/bi-services-api-interfaces';
import { createColumnHelper } from '@tanstack/react-table';
import { ComboBoxOption } from '@/types/common';
import { CheckUpCarProblemCell } from './CheckUpCarProblemCell';
import { useTranslation } from 'react-i18next';
import { DataTable } from '@/components/table/DataTable';
import { ArrowDown } from '@/components/icons/ArrowDown';
import { ArrowUp } from '@/components/icons/ArrowUp';

export interface CheckUpCarProblemsTableProps {
  tasksData: AllDeviceTask[];
  priorityOptions: ComboBoxOption[];
}

const CheckUpCarProblemsTable = ({ tasksData, priorityOptions }: CheckUpCarProblemsTableProps) => {
  const columnHelper = createColumnHelper<AllDeviceTask>();
  const { t } = useTranslation('carCheckUpModal');

  const renderSortingIcon = (sorted: 'asc' | 'desc') => {
    if (sorted === 'asc') {
      return <ArrowDown className="dark:text-white" size="sm" />;
    }
    if (sorted === 'desc') {
      return <ArrowUp className="dark:text-white" size="sm" />;
    }
    return <></>;
  };

  const columns = [
    columnHelper.accessor('serviceDate', {
      cell: CheckUpCarProblemCell,
      meta: { width: 'w-1/12 py-1' },
      header: ({ column }) => {
        const isSorted = column.getIsSorted();
        return (
          <div className="flex items-center justify-center">
            {t('table.column.serviceDate')}
            {renderSortingIcon(isSorted as 'asc' | 'desc')}
          </div>
        );
      },
    }),
    columnHelper.accessor('serviceName', {
      cell: CheckUpCarProblemCell,
      meta: { width: 'w-5/12' },
      header: ({ column }) => {
        const isSorted = column.getIsSorted();
        return (
          <div className="flex items-center">
            {t('table.column.serviceName')}
            {renderSortingIcon(isSorted as 'asc' | 'desc')}
          </div>
        );
      },
    }),
    columnHelper.accessor('serviceDescription', {
      cell: CheckUpCarProblemCell,
      meta: { width: 'w-4/12' },
      header: ({ column }) => {
        const isSorted = column.getIsSorted();
        return (
          <div className="flex items-center">
            {t('table.column.serviceDescription')}
            {renderSortingIcon(isSorted as 'asc' | 'desc')}
          </div>
        );
      },
    }),
    columnHelper.accessor('taskStatus', {
      cell: CheckUpCarProblemCell,
      meta: { width: 'w-1/12 py-1' },
      header: ({ column }) => {
        const isSorted = column.getIsSorted();
        return (
          <div className="flex items-center justify-center">
            {t('table.column.taskStatus')}
            {renderSortingIcon(isSorted as 'asc' | 'desc')}
          </div>
        );
      },
    }),
    columnHelper.accessor('priority', {
      cell: CheckUpCarProblemCell,
      meta: { width: 'w-1/12 py-1', priorityOptions },
      header: ({ column }) => {
        const isSorted = column.getIsSorted();
        return (
          <div className="flex items-center justify-center">
            {t('table.column.priority')}
            {renderSortingIcon(isSorted as 'asc' | 'desc')}
          </div>
        );
      },
    }),
  ];

  const initialSorting = [
    {
      id: 'serviceDate',
      desc: true,
    },
  ];

  return (
    <DataTable<AllDeviceTask, any>
      columns={columns}
      data={tasksData}
      enableStripedRows
      variant="default"
      initialSorting={initialSorting}
      enablePagination={false}
    />
  );
};

export default CheckUpCarProblemsTable;
