import React from 'react';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry';

export enum QueryErrorStatus {
  FETCH_ERROR = 'FETCH_ERROR',
  CUSTOM_ERROR = 'CUSTOM_ERROR',
}

interface QueryErrorEvent {
  status: QueryErrorStatus;
  data?: any;
  error?: AxiosError;
  maxRetriesReached?: boolean;
}

export class QueryErrorEmitter {
  private errorListeners: ((error: QueryErrorEvent) => void)[] = [];
  private successListeners: (() => void)[] = [];

  onQueryError(listener: (error: QueryErrorEvent) => void) {
    this.errorListeners.push(listener);
    return () => {
      this.errorListeners = this.errorListeners.filter((l) => l !== listener);
    };
  }

  onQuerySuccess(listener: () => void) {
    this.successListeners.push(listener);
    return () => {
      this.successListeners = this.successListeners.filter((l) => l !== listener);
    };
  }

  emitError(error: QueryErrorEvent) {
    this.errorListeners.forEach((listener) => listener(error));
  }

  emitSuccess() {
    this.successListeners.forEach((listener) => listener());
  }
}

export const queryErrorEmitter = new QueryErrorEmitter();
export const MAX_RETRY_ATTEMPTS = 3;

export const getRetryConfig: () => IAxiosRetryConfig = () => ({
  retries: MAX_RETRY_ATTEMPTS,
  retryDelay: (retryCount: number, error: AxiosError) => {
    return axiosRetry.exponentialDelay(retryCount, error, 1000);
  },
  retryCondition: (error: AxiosError) => {
    const isServerUnavailable = error.response?.status && error.response.status >= 502 && error.response.status <= 504;
    return (
      (axiosRetry.isNetworkOrIdempotentRequestError(error) ||
        error.response?.status === 500 ||
        error.response?.status.toString().startsWith('4')) &&
      !isServerUnavailable
    );
  },
  onRetry: (retryCount: number, error: AxiosError) => {
    const isServerUnavailable = error.response?.status && error.response.status >= 502 && error.response.status <= 504;

    if (isServerUnavailable || retryCount === MAX_RETRY_ATTEMPTS) {
      const errorEvent = {
        status: isServerUnavailable || error.code === 'ERR_NETWORK' ? QueryErrorStatus.FETCH_ERROR : QueryErrorStatus.CUSTOM_ERROR,
        data: error.response?.data,
        error,
        maxRetriesReached: retryCount === MAX_RETRY_ATTEMPTS,
      };
      queryErrorEmitter.emitError(errorEvent);
    }
  },
});

export const cleanParams = (config: AxiosRequestConfig<any>): AxiosRequestConfig<any> => {
  if (config.params) {
    Object.keys(config.params).forEach((key) => {
      if (Array.isArray(config.params[key])) {
        if (config.params[key].length === 1) {
          config.params[key] = config.params[key][0];
        } else {
          config.params[key] = config.params[key].join(',');
        }
      }
    });
  }
  return config;
};

export const onFulfilledResponse = (response: AxiosResponse<any, any>): any => {
  queryErrorEmitter.emitSuccess();
  return response;
};

interface ErrorResponse {
  message?: string;
  [key: string]: unknown;
}

export const onFailedResponse = async (error: AxiosError) => {
  if (!navigator.onLine) {
    return Promise.reject(error);
  }

  if (error.response?.status === 401) {
    window.location.href = '/login';
    return Promise.reject(error);
  }

  if (error.config?.url === '/external/ors-route') {
    return Promise.reject(error);
  }

  const errorData = error.response?.data as ErrorResponse;
  const isErrorMessageString = typeof errorData?.message === 'string';
  const message = isErrorMessageString ? errorData.message : error.response?.statusText || 'Error occurred';
  const traceId = error.response?.headers?.['x-trace-id'];

  const toastContent = (
    <div className="flex flex-col gap-1">
      <span className="flex leading-5">{message}</span>
      {traceId && <p className={'font-bold text-xs'}>Trace ID: {traceId}</p>}
    </div>
  );

  toast(toastContent, { type: 'error' });
  return Promise.reject(error);
};
