import { cn } from '@/utils/cn';
import React, { forwardRef, PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom';

interface ModalWrapperProps {
  isVisible: boolean;
  title?: string;
  titleAdditionalElement?: React.ReactNode;
  wide?: boolean;
  className?: string;
  titleClassName?: string;
}

const ModalWrapper = forwardRef<HTMLDivElement, PropsWithChildren<ModalWrapperProps>>(
  ({ isVisible, children, title, titleAdditionalElement, wide, className, titleClassName }, ref) => {
    useEffect(() => {
      if (isVisible) {
        document.body.style.overflow = 'hidden';
        return;
      }

      document.body.style.overflow = 'auto';
    }, [isVisible]);

    return (
      isVisible &&
      ReactDOM.createPortal(
        <>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="top-0 left-0 z-[60] fixed bg-black opacity-50 w-screen h-dynamic-100"
          />
          <div
            ref={ref}
            className="top-0 left-0 z-[60] fixed place-items-center grid w-screen h-dynamic-100 overflow-x-hidden overflow-y-auto outline-none"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div
              className={cn(
                'bg-white overflow-hidden relative mx-auto my-0 max-w-[70vw] h-fit md:max-h-[90%] z-[100] p-4',
                'dark:bg-gray-800 text-gray-800 dark:text-gray-200',
                'rounded-bl-[3px] rounded-br-[3px] shadow-lg',
                { 'md:max-w-[95vw]': wide },
                'max-w-full md:max-w-[85vw]',
                'rounded-none sm:rounded',
                'w-full',
                className,
              )}
            >
              {title && (
                <div
                  className={'flex items-baseline'}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <p className={cn('modal-title', titleClassName)}>{title}</p>
                  {titleAdditionalElement && titleAdditionalElement}
                </div>
              )}
              {children}
            </div>
          </div>
        </>,
        document.body,
      )
    );
  },
);

ModalWrapper.displayName = 'ModalWrapper';

export default ModalWrapper;
