import React, { useEffect, useState } from 'react';
import ModalWrapper from './ModalWrapper';
import { useTranslation } from 'react-i18next';
import { DataTable } from '../table/DataTable';
import { createColumnHelper } from '@tanstack/react-table';
import Button from '../elements/Button';
import { ArrowDown } from '../icons/ArrowDown';
import { ArrowUp } from '../icons/ArrowUp';
import CheckMark from '@/components/icons/CheckMark';
import CrossMark from '@/components/icons/CrossMark';
import { RouteGroupItem } from '@ekt-group/general-purpose-api-interfaces';

interface CacheStorageModalProps {
  isVisible: boolean;
  onClose: () => void;
}

interface CacheResponse extends Response {
  url: string;
  date: string;
  itemCount: number;
  parsedData?: any;
}

interface CacheData {
  cacheName: string;
  itemCount: number;
  lastModified: string;
  responses?: CacheResponse[];
}

const CacheStorageModal: React.FC<CacheStorageModalProps> = ({ isVisible, onClose }) => {
  const { t } = useTranslation('cacheModal');
  const [cacheData, setCacheData] = useState<CacheData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadCacheData = async () => {
    setIsLoading(true);
    try {
      if (!window?.caches) {
        console.error('Cache API is not available');
        setCacheData([]);
        return;
      }

      const caches = await window.caches.keys();
      const cacheDetails = await Promise.all(
        caches.map(async (cacheName) => {
          const cache = await window.caches.open(cacheName);
          const keys = await cache.keys();
          const lastModified = keys.length > 0 ? (await cache.match(keys[0]))?.headers.get('date') || 'N/A' : 'N/A';
          const responses = await Promise.all(
            keys.map(async (key) => {
              const response = await cache.match(key);
              if (!response) {
                return null;
              }

              const date = response.headers.get('date') || response.headers.get('last-modified');
              const clone = response.clone();
              let itemCount = 1;
              let parsedData;
              try {
                parsedData = await clone.json();
                itemCount = Array.isArray(parsedData) ? parsedData.length : 1;
              } catch {
                // If we can't parse JSON, assume it's a single item
              }

              return {
                ...response,
                headers: response.headers,
                date,
                url: response.url,
                status: response.status || 200,
                itemCount,
                parsedData,
              } as CacheResponse;
            }),
          );

          return {
            cacheName,
            itemCount: keys.length,
            lastModified,
            responses: responses.filter((r): r is Omit<typeof r, 'parsedData'> & { parsedData: any } => r !== null),
          };
        }),
      );
      setCacheData(cacheDetails.filter((cache) => !cache.cacheName.includes('workbox-precache-v2') && cache.cacheName !== 'user'));
    } catch (error) {
      console.error('Error loading cache data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isVisible) {
      loadCacheData();
    }
  }, [isVisible]);

  const columnHelper = createColumnHelper<CacheData>();

  const renderSortingIcon = (sorted: 'asc' | 'desc') => {
    if (sorted === 'asc') {
      return <ArrowDown className="dark:text-white" size="sm" />;
    }
    if (sorted === 'desc') {
      return <ArrowUp className="dark:text-white" size="sm" />;
    }
    return <></>;
  };

  const columns = [
    columnHelper.accessor('cacheName', {
      header: ({ column }) => {
        const isSorted = column.getIsSorted();
        return (
          <div className="flex items-center justify-center">
            {t('cacheName')}
            {renderSortingIcon(isSorted as 'asc' | 'desc')}
          </div>
        );
      },
      cell: (info) => {
        const value = info.getValue();
        const camelCaseValue = value.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
        return (
          <div className="flex flex-row items-center justify-between p-2 md:block">
            <span className="flex-shrink-0 md:hidden w-[100px] font-bold text-sm">{t('cacheName')}:</span>
            <span className="flex-1 text-right md:text-left">{t(`${camelCaseValue}Label`)}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor('itemCount', {
      header: ({ column }) => {
        const isSorted = column.getIsSorted();
        return (
          <div className="flex items-center justify-center">
            {t('itemCount')}
            {renderSortingIcon(isSorted as 'asc' | 'desc')}
          </div>
        );
      },
      cell: (info) => (
        <div className="flex flex-row items-center justify-between p-2 md:block">
          <span className="flex-shrink-0 md:hidden w-[100px] font-bold text-sm">{t('itemCount')}:</span>
          <span className="flex-1 text-right md:text-left">{info.getValue()}</span>
        </div>
      ),
    }),
  ];

  const handleRefresh = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    loadCacheData();
  };

  const handleReloadApp = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    window.location.reload();
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onClose();
  };

  const renderExpandedContent = (row: CacheData) => {
    if (!row.responses?.length) {
      return null;
    }

    const getDateFromResponse = (response: CacheResponse) => {
      try {
        const url = new URL(response.url);
        const startDate = url.searchParams.get('startDate');
        if (startDate) {
          return new Date(startDate).toLocaleString();
        }
        return new Date(response.date).toLocaleString();
      } catch {
        return new Date(response.date).toLocaleString();
      }
    };

    const getRouteGroupName = (response: CacheResponse) => {
      try {
        const headerName = response.headers?.get('x-route-name');
        if (headerName) {
          return headerName;
        }

        // Fallback to parsedData if headers don't contain the route name
        if (response.parsedData?.[0]?.routeGroup?.name) {
          return response.parsedData[0].routeGroup.name;
        }
        return null;
      } catch {
        return null;
      }
    };

    return (
      <div className="flex flex-col gap-2">
        <div className="grid grid-cols-2 gap-4">
          {row.responses.map((response, index) => (
            <div key={index} className="p-3 bg-gray-100 rounded dark:bg-gray-700">
              <div className="flex flex-col gap-1">
                {row.cacheName === 'route-group-items' && (
                  <div className="flex items-center justify-between">
                    <span className="font-bold">{t('date')}:</span>
                    <span>{getDateFromResponse(response)}</span>
                  </div>
                )}
                {row.cacheName === 'waypoints' && (
                  <div className="flex items-center justify-between">
                    <span className="font-bold">{t('route')}:</span>
                    <span>{getRouteGroupName(response)}</span>
                  </div>
                )}
                <div className="flex items-center justify-between">
                  <span className="font-bold">{t('status')}:</span>
                  {response.status === 200 ? (
                    <CheckMark size="sm" className="text-green-500" />
                  ) : (
                    <CrossMark size="sm" className="text-red-500" />
                  )}
                </div>
                <div className="flex items-center justify-between">
                  <span className="font-bold">{t('itemCount')}:</span>
                  <span>{response.itemCount}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <ModalWrapper
      isVisible={isVisible}
      title={t('cacheStorage')}
      className="self-start w-full max-w-4xl mt-10 overflow-auto dark:bg-zinc-800 h-fit"
      titleClassName="w-full text-center font-bold text-xl"
    >
      <div className="flex flex-col gap-4 p-4" onClick={(e) => e.stopPropagation()}>
        <div className="flex justify-between gap-2">
          <Button text={t('refresh')} size="md" onClick={handleRefresh} disabled={isLoading} />
          <Button text={t('reloadApp')} size="md" onClick={handleReloadApp} />
          <Button text={t('close')} size="md" onClick={handleClose} color="disabled" />
        </div>
        <small className="text-center text-gray-500 dark:text-gray-400">{t('reloadAppHint')}</small>
        {isLoading ? (
          <div className="text-center">{t('loading')}</div>
        ) : (
          <DataTable<CacheData, any>
            columns={columns}
            data={cacheData}
            variant="default"
            enablePagination={false}
            getRowCanExpand={(row) => (row.responses?.length ?? 0) > 0}
            renderExpandedContent={renderExpandedContent}
          />
        )}
      </div>
    </ModalWrapper>
  );
};

export default CacheStorageModal;
