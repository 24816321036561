export const getTomorrowDate = (): string => {
  const today = new Date();
  const tomorrow = new Date(today);

  tomorrow.setDate(today.getDate() + 1);

  return tomorrow
    .toLocaleString('et', {
      hour12: false,
      timeZone: 'Europe/Tallinn',
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    })
    .split('.')
    .reverse()
    .join('-');
};

export const getTodayDate = (): string => {
  return new Date()
    .toLocaleString('et', {
      hour12: false,
      timeZone: 'Europe/Tallinn',
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    })
    .split('.')
    .reverse()
    .join('-');
};

export const getDaysAgoDate = (daysAgo: number): string => {
  const today = new Date();
  const daysAgoDate = new Date(today);

  daysAgoDate.setDate(today.getDate() - daysAgo);

  return daysAgoDate.toISOString().split('T')[0];
};

export const getFutureDaysDate = (daysAhead: number): string => {
  const today = new Date();
  const futureDate = new Date(today);

  futureDate.setDate(today.getDate() + daysAhead);

  return futureDate
    .toLocaleString('et', {
      hour12: false,
      timeZone: 'Europe/Tallinn',
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    })
    .split('.')
    .reverse()
    .join('-');
};

export const getEstonianWeekDay = (date: string) => {
  return new Intl.DateTimeFormat('et-EE', { weekday: 'long' }).format(new Date(date));
};

export const formatToEstonianDate = (date: string, includeTime = false) => {
  if (!date) {
    return null;
  }

  const formattedDate = getReadableDateTime(date, 'date');
  const weekDay = getEstonianWeekDay(date)[0].toUpperCase();

  if (includeTime) {
    return `${formattedDate} ${weekDay} ${getReadableDateTime(date, 'time')}`;
  }

  return `${formattedDate} ${weekDay}`;
};

export const getReadableDateTime = (date: string | Date, format: 'date' | 'time' | 'datetime') => {
  const dateFormats = ['date', 'datetime'];
  const timeFormats = ['time', 'datetime'];

  const includeDate = dateFormats.includes(format);
  const includeTime = timeFormats.includes(format);

  return Intl.DateTimeFormat('et-EE', {
    day: includeDate ? '2-digit' : undefined,
    month: includeDate ? '2-digit' : undefined,
    year: includeDate ? 'numeric' : undefined,
    hour: includeTime ? '2-digit' : undefined,
    minute: includeTime ? '2-digit' : undefined,
  }).format(new Date(date));
};

export const getTodayDateWithoutTime = () => {
  return new Date().toISOString().split('T')[0];
};

export const isToday = (date: string | Date | number) => {
  const today = getTodayDate();
  const targetDate = new Date(date).toISOString().split('T')[0];

  return today === targetDate;
};

export const FULL_DAY_TIME_IN_SECONDS = 24 * 60 * 60;
export const YEAR_TIME_IN_SECONDS = 365 * FULL_DAY_TIME_IN_SECONDS;
