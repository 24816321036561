import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getPaginationRowModel,
  getExpandedRowModel,
  ExpandedState,
} from '@tanstack/react-table';
import React, { useState } from 'react';
import { cn } from '@/utils/cn';
import { ArrowDown } from '@/components/icons/ArrowDown';
import { ArrowUp } from '@/components/icons/ArrowUp';
import { isFirefox } from 'react-device-detect';
import { PaginationControls } from './PaginationControls';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  className?: string;
  enableStripedRows?: boolean;
  variant?: 'default' | 'blue' | 'green' | 'amber' | 'gray';
  inverseColor?: boolean;
  initialSorting?: SortingState;
  enablePagination?: boolean;
  pageSize?: number;
  showPageSize?: boolean;
  getRowCanExpand?: (row: TData) => boolean;
  renderExpandedContent?: (row: TData) => React.ReactNode;
}

const VARIANT_COLORS = {
  default: {
    bg: 'bg-gray-400 dark:bg-gray-800',
    hover: 'hover:bg-orange-100 dark:hover:bg-gray-400',
    hoverLight: 'hover:bg-gray-400 dark:hover:bg-gray-400',
    inverse: {
      bg: 'bg-gray-500',
      hover: 'hover:bg-gray-600',
    },
  },
  blue: {
    bg: 'bg-blue-100',
    hover: 'hover:bg-blue-200',
    hoverLight: 'hover:bg-blue-50',
    inverse: {
      bg: 'bg-blue-500',
      hover: 'hover:bg-blue-600',
    },
  },
  green: {
    bg: 'bg-green-100',
    hover: 'hover:bg-green-200',
    hoverLight: 'hover:bg-green-50',
    inverse: {
      bg: 'bg-green-500',
      hover: 'hover:bg-green-600',
    },
  },
  amber: {
    bg: 'bg-amber-100',
    hover: 'hover:bg-amber-200',
    hoverLight: 'hover:bg-amber-50',
    inverse: {
      bg: 'bg-amber-500',
      hover: 'hover:bg-amber-600',
    },
  },
  gray: {
    bg: 'bg-gray-100',
    hover: 'hover:bg-gray-200',
    hoverLight: 'hover:bg-gray-50',
    inverse: {
      bg: 'bg-gray-500',
      hover: 'hover:bg-gray-600',
    },
  },
} as const;

const getVariantStyles = (variant: DataTableProps<unknown, unknown>['variant'] = 'default', inverseColor?: boolean) => {
  const variantColor = VARIANT_COLORS[variant];
  return inverseColor ? variantColor.inverse : variantColor;
};

const getTextColor = (inverseColor?: boolean) => (inverseColor ? 'text-white dark:text-white' : 'text-[#2e2e2e] dark:text-white');

export const SortIcon = ({ sorted }: { sorted: false | 'asc' | 'desc' }) => {
  if (sorted === 'asc') {
    return <ArrowDown className="dark:text-white" size="sm" />;
  }
  if (sorted === 'desc') {
    return <ArrowUp className="dark:text-white" size="sm" />;
  }
  return null;
};

export function DataTable<TData, TValue>({
  columns,
  data,
  className,
  enableStripedRows = true,
  variant = 'default',
  inverseColor,
  initialSorting = [],
  enablePagination = true,
  pageSize: initialPageSize = 10,
  showPageSize = false,
  getRowCanExpand,
  renderExpandedContent,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>(initialSorting);
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: initialPageSize,
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: (row) => getRowCanExpand?.(row.original) ?? false,
    ...(enablePagination
      ? {
          getPaginationRowModel: getPaginationRowModel(),
          onPaginationChange: setPagination,
          state: {
            sorting,
            expanded,
            pagination: {
              pageIndex,
              pageSize,
            },
          },
        }
      : {
          state: {
            sorting,
            expanded,
          },
        }),
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    enableSorting: true,
  });

  return (
    <div className={cn('flex flex-col flex-1 w-full md:py-2 md:px-2 gap-y-3', className)}>
      <div className="h-full overflow-auto">
        <table className="min-w-full overflow-hidden text-center border-collapse shadow-lg">
          <thead className="hidden md:table-header-group">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="divide-x divide-gray-400 dark:divide-gray-600">
                {getRowCanExpand && (
                  <th
                    className={cn(
                      getVariantStyles(variant, inverseColor).bg,
                      getVariantStyles(variant, inverseColor).hover,
                      getTextColor(inverseColor),
                      'w-10 px-2 py-4 dark:border-white text-lg',
                    )}
                  ></th>
                )}
                {headerGroup.headers.map((header) => {
                  const isSortable = header.column.getCanSort();
                  const sortingToggler = header.column.getToggleSortingHandler();
                  const isSorted = header.column.getIsSorted();
                  const meta = header.column.columnDef.meta || {};

                  return (
                    <th
                      key={header.id}
                      className={cn(
                        getVariantStyles(variant, inverseColor).bg,
                        getVariantStyles(variant, inverseColor).hover,
                        getTextColor(inverseColor),
                        'px-2 py-4 dark:border-white',
                        'text-lg',
                        'hidden md:table-cell',
                        meta.width,
                        {
                          'cursor-pointer select-none': isSortable,
                          [getVariantStyles(variant, inverseColor).bg]: isSorted,
                        },
                      )}
                      onClick={sortingToggler}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="">
            {table.getRowModel().rows.map((row, index) => (
              <React.Fragment key={row.id}>
                <tr
                  className={cn(
                    'group md:py-0 md:h-[1px] bg-gray-200 dark:bg-slate-300 dark:text-black',
                    getVariantStyles(variant, inverseColor).hover,
                    {
                      'bg-gray-300 dark:bg-slate-400': enableStripedRows && index % 2 === 0,
                      'cursor-pointer': row.getCanExpand(),
                    },
                  )}
                  onClick={row.getCanExpand() ? row.getToggleExpandedHandler() : undefined}
                >
                  {getRowCanExpand && (
                    <td className="w-10 p-2">
                      {row.getCanExpand() && (
                        <div className="p-1 rounded">
                          {row.getIsExpanded() ? <ArrowDown size="sm" className="w-4 h-4" /> : <ArrowUp size="sm" className="w-4 h-4" />}
                        </div>
                      )}
                    </td>
                  )}
                  {row.getVisibleCells().map((cell) => (
                    <td className={cn('h-full p-0 py-1 md:py-2', 'block md:table-cell', { 'md:h-[1px]': !isFirefox })} key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
                {row.getIsExpanded() && renderExpandedContent && (
                  <tr className="bg-gray-100 dark:bg-slate-200">
                    <td colSpan={getRowCanExpand ? columns.length + 1 : columns.length} className="p-4">
                      {renderExpandedContent(row.original)}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      {enablePagination && data.length > initialPageSize && (
        <PaginationControls
          currentPage={pageIndex}
          totalPages={table.getPageCount()}
          setCurrentPage={(page) => table.setPageIndex(page)}
          pageSize={pageSize}
          onPageSizeChange={(size) => table.setPageSize(size)}
          showPageSize={showPageSize}
        />
      )}
    </div>
  );
}
