import { RouteGroupItem, RouteGroupItemPlanning, RouteGroupItemPlanningService } from '@ekt-group/general-purpose-api-interfaces';
import generalService, { GetRouteGroupItemsListDto } from '../../../services/general.service';
import routeGroupItemsService, { RouteGroupItemFinishDto, RouteGroupItemStartDto } from './route-group-items.service';
import { CompletedRouteOrder, HistoricalRoutesMatchItem } from '../types';
import { baseApi, ROUTE_GROUP_ITEMS_KEY } from '@/store/baseApi';
import { BaseQueryFn, TypedMutationOnQueryStarted } from '@reduxjs/toolkit/dist/query/react/index';

const enhancedApi = baseApi.enhanceEndpoints({
  addTagTypes: [ROUTE_GROUP_ITEMS_KEY],
});

const updateRouteGroupItemsDraft = (
  payload: RouteGroupItemStartDto | RouteGroupItemFinishDto,
  draft: RouteGroupItem[],
  type: 'start' | 'finish',
) => {
  if (!draft?.length) {
    return;
  }

  if (type === 'start') {
    const item = draft.find((item) => item.id === payload.id);
    const startPayload = payload as RouteGroupItemStartDto;
    if (item) {
      item.start = startPayload.startTime;
      item.car.odometer = startPayload.odometer;
    }
    return;
  }

  const item = draft.find((item) => item.id === payload.id);
  const finishPayload = payload as RouteGroupItemFinishDto;
  if (item) {
    item.end = finishPayload.endTime;
    item.isDone = true;
  }
};

const updateRouteGroupItemsCacheOnStartOrFinish: TypedMutationOnQueryStarted<
  RouteGroupItem,
  RouteGroupItemStartDto | RouteGroupItemFinishDto,
  BaseQueryFn,
  'generalPurposeAPI'
> = async (payload, { dispatch, getState }) => {
  const endpoints = routeGroupItemsApi.util.selectInvalidatedBy(getState(), [{ type: ROUTE_GROUP_ITEMS_KEY }]);
  const type = 'startTime' in payload ? 'start' : 'finish';

  for (const endpoint of endpoints) {
    const { endpointName, originalArgs } = endpoint;
    if (endpointName !== 'getRouteGroupItemsList') {
      continue;
    }

    dispatch(
      routeGroupItemsApi.util.updateQueryData('getRouteGroupItemsList', originalArgs, (draft) =>
        updateRouteGroupItemsDraft(payload, draft, type),
      ),
    );
  }
};

export const updateRouteGroupItemsCache = (data: RouteGroupItem[], draft: RouteGroupItem[]) => {
  if (!draft?.length) {
    return;
  }

  for (const item of data) {
    const draftItem = draft.find((draftItem) => draftItem.id === item.id);
    if (draftItem) {
      draftItem.start = item.start;
      draftItem.end = item.end;
      draftItem.isDone = item.isDone;
    }
  }
};

export const routeGroupItemsApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getRouteGroupItemsList: builder.query<RouteGroupItem[], GetRouteGroupItemsListDto>({
      queryFn: async ({ userId, role }) => {
        const result = await generalService.getRouteGroupItemsList({ userId, role });
        return { data: result };
      },
      providesTags: [ROUTE_GROUP_ITEMS_KEY],
      merge: (currentCache = [], newItems) => {
        if (navigator.onLine) {
          currentCache.length = 0;
          currentCache.push(...newItems);
        }
      },
    }),
    start: builder.mutation<RouteGroupItem, RouteGroupItemStartDto>({
      queryFn: async (obj) => {
        return routeGroupItemsService.start(obj);
      },
      invalidatesTags: [ROUTE_GROUP_ITEMS_KEY],
      onQueryStarted: updateRouteGroupItemsCacheOnStartOrFinish,
    }),
    finish: builder.mutation<RouteGroupItem, RouteGroupItemFinishDto>({
      queryFn: async (obj) => {
        return routeGroupItemsService.finish(obj);
      },
      invalidatesTags: [ROUTE_GROUP_ITEMS_KEY],
      onQueryStarted: updateRouteGroupItemsCacheOnStartOrFinish,
    }),
    getHistoricalRoutesMatch: builder.query<HistoricalRoutesMatchItem[], number>({
      queryFn: async (id) => {
        return routeGroupItemsService.getHistoricalRoutesMatch(id);
      },
    }),
    getCompletedRouteOrder: builder.query<CompletedRouteOrder[], number>({
      queryFn: async (id) => {
        return routeGroupItemsService.getCompletedRouteOrder(id);
      },
    }),
    getPlanningForRouteGroupItem: builder.query<RouteGroupItemPlanning<RouteGroupItemPlanningService.Vroom>[], number>({
      queryFn: async (id) => {
        return routeGroupItemsService.getPlanningForRouteGroupItem(id);
      },
    }),
  }),
});

export const {
  useGetRouteGroupItemsListQuery,
  useLazyGetRouteGroupItemsListQuery,
  useStartMutation,
  useFinishMutation,
  useGetHistoricalRoutesMatchQuery,
  useLazyGetHistoricalRoutesMatchQuery,
  useLazyGetCompletedRouteOrderQuery,
  useGetPlanningForRouteGroupItemQuery,
  useLazyGetPlanningForRouteGroupItemQuery,
} = routeGroupItemsApi;
