import React from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';
import Button from '../elements/Button';
import { useTranslation } from 'react-i18next';

function ReloadPrompt() {
  const { t } = useTranslation();

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      // eslint-disable-next-line prefer-template, no-console
      console.log('SW Registered: ' + r);
    },
    onRegisterError(error) {
      console.error('SW registration error', error);
    },
    immediate: true,
  });

  const close = () => {
    setNeedRefresh(false);
  };

  const reloadPage = async () => {
    const registration = await navigator.serviceWorker.getRegistration();
    if (!registration?.waiting) {
      return;
    }

    // Send skip waiting message to waiting service worker
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });

    updateServiceWorker(true);

    // Reload once the new service worker takes control
    registration.addEventListener('controllerchange', () => {
      window.location.reload();
    });
  };

  return (
    <div className="right-0 md:right-4 bottom-0 md:bottom-4 z-[9999] fixed m-0 p-0 w-full md:w-auto">
      {needRefresh && (
        <div className="flex flex-col gap-1 p-3 bg-white border border-gray-300 rounded rounded-b-none shadow-lg">
          <div className="text-gray-900">
            <span>{t('newContentPrompt', { ns: 'offline' })}</span>
          </div>
          <div className="flex gap-2">
            <Button text={t('reload')} onClick={reloadPage} />
            <Button text={t('close')} onClick={close} />
          </div>
        </div>
      )}
    </div>
  );
}

export default ReloadPrompt;
